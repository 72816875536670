<template>
  <div>
    <template v-if="!selected_color">
      <b-form-group
        label="Nombre"
        label-for="input-name"
        label-cols="4"
        label-cols-sm="3"
        class="label"
      >
        <b-form-input
          id="input-name"
          name="input-name"
          v-model="$v.cognitive_level.name.$model"
          :state="validateState('name')"
          aria-describedby="input-name-live-feedback"
          size="sm"
        ></b-form-input>
        <b-form-invalid-feedback id="input-name-live-feedback"
          >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        label="Descripción"
        label-for="input-description"
        label-cols="4"
        label-cols-sm="3"
        class="label"
      >
        <b-form-textarea
          id="input-description"
          name="input-description"
          v-model="$v.cognitive_level.description.$model"
          :state="validateState('description')"
          aria-describedby="input-description-live-feedback"
          size="md"
          max-rows="8"
        ></b-form-textarea>
        <b-form-invalid-feedback id="input-description-live-feedback"
          >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        label="Nivel"
        label-for="input-level"
        label-cols="4"
        label-cols-sm="3"
        class="label"
      >
        <b-form-input
          id="input-level"
          name="input-level"
          v-model="$v.cognitive_level.level.$model"
          :state="validateState('level')"
          aria-describedby="input-level-feedback"
          size="sm"
          type="number"
          :min="1"
        ></b-form-input>
        <b-form-invalid-feedback id="input-level-feedback"
          >Este campo debe ser mayor a 0.</b-form-invalid-feedback
        >
      </b-form-group>
    </template>
    <b-form-group
      v-if="!selected_color && defaultColor"
      label="Color por defecto:"
      label-for="input-color"
      label-cols="3"
      label-cols-sm="4"
      class="label"
    >
      <b-button
        v-b-tooltip.v-secondary.noninteractive="'Aplicar el color por defeto'"
        class="mt-1 cognitive-level-color"
        :style="{
          'background-color': defaultColor,
          color: defaultColor,
        }"
        @click="() => (cognitive_level.color = defaultColor)"
      ></b-button>
    </b-form-group>
    <b-form-group
      label="Color:"
      label-for="input-color"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <div class="d-flex">
        <div class="d-block w-100">
          <b-form-input
            type="color"
            id="input-color"
            name="input-color"
            v-model="$v.cognitive_level.color.$model"
            :state="validateState('color')"
            aria-describedby="input-color-live-feedback"
            size="md"
            rows="3"
            max-rows="8"
          >
          </b-form-input>
        </div>
        <button
          class="btn-clear-inputs ml-2"
          v-b-tooltip.v-secondary.noninteractive="`Color por defecto`"
          @click="() => (cognitive_level.color = '#ffffff')"
        >
          <b-icon font-scale="1.5" icon="x-square"></b-icon>
        </button>
      </div>
    </b-form-group>
    <div class="row" v-if="!selected_color">
      <div v-if="show_delete_button" class="col" style="text-align: left">
        <!-- <b-button class="mr-1" size="sm" variant="danger" @click="remove">Eliminar</b-button> -->
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId } from "@/utils/utils";
import { validationMixin } from "vuelidate";
// import { required, minLength, minValue } from "vuelidate/lib/validators";

export default {
  name: "CognitiveLevelForm",
  mixins: [validationMixin],
  props: {
    taxonomy_id: {
      type: Number,
      required: true,
    },
    order: {
      type: Number,
      required: true,
    },
    CognitiveLevel: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          level: this.order,
          name: "",
          description: "",
          active: true,
          taxonomy: this.taxonomy_id,
          color: "#FFFFFF",
          verbs: [],
        };
      },
    },
    selected_color: {
      type: Boolean,
      default: false,
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      default_color: [
        { value: 1, color: "#A2D2FF", label: "Azul pastel" },
        { value: 2, color: "#D7A9E3", label: "Lila pastel" },
        { value: 3, color: "#A1FFC4", label: "Verde pastel" },
        { value: 4, color: "#FFFFD1", label: "Amarillo pastel" },
        { value: 5, color: "#FFD1DC", label: "Rosa pastel" },
        { value: 6, color: "#FFC5A1", label: "Melocotón pastel" },
      ],
      cognitive_level: this.selected_color
        ? this.CognitiveLevel
        : { ...this.CognitiveLevel },
    };
  },
  computed: {
    defaultColor() {
      return (
        (
          this.default_color.find(
            (x) => x.value == this.cognitive_level.level
          ) || {}
        ).color || null
      );
    },
  },
  validations: {
    cognitive_level: {
      name: {
        // required,
        // minLength: minLength(5),
        valid() {
          if (this.selected_color) return true;
          else if (this.cognitive_level.name.length >= 5) return true;
          else return false;
        },
      },
      description: {
        // required,
        // minLength: minLength(5),
        valid() {
          if (this.selected_color) return true;
          else if (this.cognitive_level.description.length >= 5) return true;
          else return false;
        },
      },
      level: {
        // required,
        // minValue: minValue(1),
        valid() {
          if (this.selected_color) return true;
          else if (this.cognitive_level.level >= 1) return true;
          else return false;
        },
      },
      color: {},
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.cognitive_level[name];
      return $dirty ? !$error : null;
    },
    save() {
      if (isNaN(this.cognitive_level.id)) this.createCognitiveLevel();
      else this.updateCognitiveLevel();
    },
    createCognitiveLevel() {
      this.$v.cognitive_level.$touch();
      if (this.$v.cognitive_level.$anyError) {
        return;
      }
      this.$restful
        .Post(`/mesh/cognitive-level/`, this.cognitive_level)
        .then((response) => {
          this.cognitive_level = response;
          this.$emit("created", this.cognitive_level);
        });
    },
    updateCognitiveLevel() {
      this.$v.cognitive_level.$touch();
      if (this.$v.cognitive_level.$anyError) {
        return;
      }
      this.$restful
        .Put(
          `/mesh/cognitive-level/${this.cognitive_level.id}/`,
          this.cognitive_level
        )
        .then((response) => {
          this.cognitive_level = response;
          this.$emit("updated", this.cognitive_level);
        });
    },
  },
};
</script>

<style scoped>
.cognitive-level-color {
  align-self: center;
  width: 90% !important;
  height: 20px !important;
  opacity: 1;
  content: " #";
}
.btn-clear-inputs {
  border: none;
  background: transparent;
  font-size: 11pt;
  padding: 0;
  margin-left: 0.7rem;
}
.btn-clear-inputs {
  font-size: 10pt;
}
</style>